import type { GlobalLocalization } from "common-components-nuxt-types/globalLocalization";

const defaultLocalization: GlobalLocalization = {
  formInvalid: "Formularz zawiera błędy",
  formInvalidDescription: "Zapoznaj się z komunikatami.",
  previousFormPartsInvalidDescription:
    "Zapoznaj się z komunikatami tej oraz poprzednich części formularza",
  or: "lub",
  and: "i",
  back: "Powrót",
  backToSystem: "Powrót do systemu",
  next: "Dalej",
  save: "Zapisz",
  edit: "Edytuj",
  add: "Dodaj",
  boy: "chłopiec",
  girl: "dziewczynka",
  clear: "Wyczyść",
  search: "Wyszukaj",
  cancel: "Anuluj",
  close: "Zamknij",
  privatePolicy: "Polityka prywatności",
  privatePolicyText:
    'Klikając w przycisk "rozumiem" przyjmujesz do wiadomości, że ta strona korzysta z ciasteczek celem świadczenia bezpiecznych usług na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie. Jeżeli nie wyrażasz zgody na korzystanie z ciasteczek, które nie są niezbędne do korzystania ze strony, możesz dokonać zmian ustawień w przeglądarce, z której korzystasz. Kliknij aby dowiedzieć się więcej',
  agree: "Rozumiem",
  copyRight: "Copyright © Asseco Data Systems",
  contact: "Kontakt",
  pageMenu: "Mapa strony",
  homePage: "Strona główna",
  errorOccured: "Wystąpił błąd nr",
  errorOccuredNotRegistered: "Wystąpił błąd",
  saveErrorCode: "Wyślij kod błędu na adres ",
  sendErrorDetails: "Podaj adres strony i krótki opis błędu w treści maila.",
  supportEmail: "pzo@assecods.pl",
  savedErrorCodeRecipient: "Pomocy Technicznej ",
  errorReturnInstruction:
    'Aby kontynuować pracę prosimy kliknąć przycisk "Powrót".',
  setting: " Ustawienia konta",
  passwordsNotTheSame: "Hasła nie są takie same",
  signSuccess:
    "Pomyślnie podpisano podpisem zaufanym. Trwa weryfikacja poprawności.",
  signFailed: "Wystąpił błąd podczas podpisywania podpisem zaufanym.",
  signCancel: "Przerwano proces podpisywania profilem zaufanym.",
  loginMMSuccess: "Pomyślnie zalogowano przez portal Moje Miasto.",
  loginWkSuccess: "Pomyślnie zalogowano przez Węzeł Krajowy.",
  attention: "Uwaga!",
  download: "Pobierz",
  waitForRedirect: "Proszę czekać, za chwilę nastąpi przekierowanie ...",
  noData: "Brak danych",
  noScript:
    "Do poprawnego działania systemu JavaScript musi byc odblokowany w przeglądarce!",
  statusResultDefaultErrorTitle: "Wystąpił błąd",
  statusResultDefaultWarningTitle: "Uwaga",
  statusResultDefaultSuccessTitle: "Operacja zakończona powodzeniem",
  all: "Wszystko",
  filters: "Filtry",
  hourFrom: "Godzina od",
  hourTo: "Godzina do",
  hourFromNotAfterHourTo: "Godzina od nie może być późniejsza niż godzina do",
  unsupportedBrowserMessage:
    "Twoja przeglądarka nie jest wspierana!<br/>Używasz wersji przeglądarki, która nie obsługuje wielu nowoczesnych technologii, więc niektóre strony są wyświetlane nieprawidłowo, a co najważniejsze - nie wszystkie funkcje mogą działać na stronach. Dlatego proszę otworzyć stronę w innej przeglądarce lub nowszej wersji.",
  applicationFileName: "karta_zapisu",
  signedApplicationFileName: "podpisana_karta_zapisu",
  qualificationFileName: "karta_kwalifikacji",
  signedQualificationFileName: "podpisana_karta_kwalifikacji",
  mobile: "mobilna",
  bigScreen: "duża",
  pageNotFound: "Uups! Strona nie została znaleziona",
  pageNotFoundDescription:
    "Taka strona nie istnieje w naszym serwisie, sprawdź, czy wpisałeś poprawny URL w pasku adresu przeglądarki. Aby kontynuować pracę wciśnij przycisk poniżej.",
};

export function useGlobalLocalization() {
  return defaultLocalization;
}
